@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Juana';
  src: url('../src/assets/font/Juana.otf');
}

@font-face {
  font-family: 'ABC';
  src: url('../src/assets/font/ABC.ttf');
}


#details-bg{
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.62);
border-radius: 10px;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(4.2px);
-webkit-backdrop-filter: blur(5.2px);
/* border: 1px solid rgba(255, 255, 255, 0.04); */
}

#info_bg{
  background-image: linear-gradient(0deg, rgba(30, 81, 202, 0.30), rgba(255, 255, 255)),url('./assets/images/help.webp');
   background-size: cover;
    background-position: center;
    width: 100%;
   }
   #about_bg{
    background-image: linear-gradient(0deg, rgba(12, 36, 94, 0.40), rgba(248,249,252)),url('https://www.skillcast.com/hubfs/Blog_Images/Blogs_Industry/construction-workers-1200-627.jpeg');
     background-size: cover;
      background-repeat: no-repeat;
    width: 100%;
    background-position: center;

      
     }
     #team_bg{
      background-image: linear-gradient(0deg, rgba(12, 36, 94, 0.42), rgba(255,255,255)),url('./assets/images/team.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      
     }
     #build{
      background-image: linear-gradient(0deg, rgba(12, 36, 94, 0.92), rgba(34, 55, 140, 0.64)),url('./assets/images/team.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      
     }
     #card{
      animation: myAn 35s linear 15s infinite forwards;
     }
     @keyframes myAn {
      0% {
        transform: translateX(0%);
      }
    
      100% {
        transform: translateX(-100%);
      }
    }

     #project1_bg{
      background-image: linear-gradient(0deg, rgba(12, 36, 94, 0.40), rgba(255,255,255, 0.86)),url('./assets/images/project.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      
     }
     #stroke {
      -webkit-text-stroke: 2px #01033E;
      text-stroke: 2px #01033E;
      letter-spacing: 10px;
     }
     #strokes {
      -webkit-text-stroke: 1px cyan;
      text-stroke: 1px cyan;
      letter-spacing: 10px;
     }
     #home{
      background-image: linear-gradient(0deg, rgba(40, 40, 136, 0.10), rgba(201,203,212)),url('https://img.freepik.com/premium-photo/construction-worker-safety-construction-site-with-blue-helmet_1168123-7705.jpg');
      /* background-image: url('./assets/images/homebg.jpg'); */
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      background-position: center;
      
     }
     #service{
      background: rgba(244, 241, 241, 0.96);
      /* border-radius: 16px; */
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5.1px);
      -webkit-backdrop-filter: blur(2.1px);
      border: 1px solid rgba(255, 255, 255, 0.04);
     }
     #name{
      font-size: clamp(45px, 10vw, 8.1rem);
     }
     #headings{
      font-size: clamp(35px, 10vw, 6rem);
     }
  
   .active{
    background-color: #2F4793;
    border-radius: 5px;
    color: white;
    padding: 5px 8px;
    transition: ease-in-out;
    transition-duration: 500;
  
   }
   #footer_bg{
    background-image: linear-gradient(0deg, rgba(130, 130, 177, 0.88), rgb(186, 191, 203)),url('https://images.pexels.com/photos/14484423/pexels-photo-14484423.jpeg?auto=compress&cs=tinysrgb&w=600');
      background-size: cover;
      background-position: center;
     }

     #service_bg{
      background-image: linear-gradient(0deg, rgba(40, 40, 136, 0.10), rgba(201,203,212)),url('./assets/images/landing3.jpg');
        background-size: cover;
        background-position: center;
       }
       /*  */
     #app {
      width: 100%;
      margin: auto;
      /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5); */
      padding: 2rem 0rem;
      
    }
    
    .swiper {
      padding: 2rem;
    }
    
    .swiper .res-slide {
      min-width: 300px;
      max-width: 400px;
      min-height: 350px;
      /* border: 2px solid cyan; */
      border-radius: 10px;
    }
    
    
    .res-slide:nth-child(1) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-002.jpg");
    }
    .res-slide:nth-child(2) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-005.jpg");
    }
    .res-slide:nth-child(3) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-006.jpg");
    }
    .res-slide:nth-child(4) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-007.jpg");
    }
    .res-slide:nth-child(5) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-014.jpg");
    }
    .res-slide:nth-child(6) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-015.jpg");
    }
    .res-slide:nth-child(7) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-016.jpg");
    }
    .res-slide:nth-child(8) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-017.jpg");
    }
    .res-slide:nth-child(11) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-018.jpg");
    }
    .res-slide:nth-child(12) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-019.jpg");
    }
    .res-slide:nth-child(13) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-020.jpg");
    }
    .res-slide:nth-child(14) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-021.jpg");
    }
    .res-slide:nth-child(15) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-022.jpg");
    }
    .res-slide:nth-child(16) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-023.jpg");
    }
    .res-slide:nth-child(17) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-024.jpg");
    }
    .res-slide:nth-child(18) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-025.jpg");
    }
    .res-slide:nth-child(19) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-026.jpg");
    }
    .res-slide:nth-child(20) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-027.jpg");
    }
    .res-slide:nth-child(21) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-028.jpg");
    }
    .res-slide:nth-child(22) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-030.jpg");
    }
    .res-slide:nth-child(23) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-031.jpg");
    }
    .res-slide:nth-child(24) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-032.jpg");
    }
    .res-slide:nth-child(25) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-033.jpg");
    }
    .res-slide:nth-child(26) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-034.jpg");
    }
    .res-slide:nth-child(27) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-035.jpg");
    }
    .res-slide:nth-child(28) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-036.jpg");
    }
    .res-slide:nth-child(29) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-037.jpg");
    }
    .res-slide:nth-child(30) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-038.jpg");
    }
    .res-slide:nth-child(10) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-000.jpg");
    }
    .res-slide:nth-child(9) {
      background: center / cover no-repeat url("./assets//images/DAROBET\ WEBSITE.pdf-image-001.jpg");
    }