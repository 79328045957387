@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Unica+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Juana';
  src: url('../assets/font/Juana.otf');
}
@font-face {
  font-family: 'ABC';
  src: url('../assets/font/ABC.ttf');
}


#projects_bg{
  background-image: linear-gradient(0deg, rgba(12, 36, 94, 0.27), rgba(34, 55, 140, 0.64)),url('../assets/images/Wave Line.svg');
  background-size: cover;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  
 }
#project_bg{
  background-image: linear-gradient(0deg, rgba(12, 36, 94, 0.72), rgba(34, 55, 140, 0.64)),url('https://businessjournaldaily.com/wp-content/uploads/2023/09/Construction.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  background-position: center;
  
 }
 #shadow_img{
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.34);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  backdrop-filter: blur(9.8px);
  -webkit-backdrop-filter: blur(7.8px);
 }

 .actives{
  border-left: 4px solid orangered;
  /* border-right: 4px solid orangered; */
  padding: 0px 7px;
 }
 #home_img{
  border-radius: 85% 60% 70% 30% / 44% 35% 65% 56% ;
 }
