.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 2px white;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition-property: ease-in-out;
    transition-duration: 400ms;
    transition-delay: 200ms;
  }
  
  .slide-hidden {
    display: none;
  }
  
  .arrow {
    position: absolute;
    filter: drop-shadow(0px 0px 5px #555);
    width: 2rem;
    height: 2rem;
    color: white;
  }
  
  .arrow:hover {
    cursor: pointer;
  }
  
  .arrow-left {
    left: 1rem;
  }
  
  .arrow-right {
    right: 1rem;
  }
  
  .indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
  }
  
  .indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
  }
  
  .indicator-inactive {
    background-color: #2F4793;
  }